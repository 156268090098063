<template>
  <v-row
    align="center"
    justify="center">
    <v-col
      cols="12"
      sm="8">
      <v-card>
        <v-card-title class="d-flex justify-space-between">
          <span> {{ $t("crudActions.indexModel", { model: $t("models.domains.multiple") }) }} </span>
          <v-btn
            small
            color="primary"
            :to="{ name: $routes.domain.createRoute.name }">
            {{ $t("crudActions.createModel", { model: $t("models.domains.single") }) }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :loading="loading"
            :headers="tableColumns"
            :items="domains">
            <template #item.action="{ item }">
              <v-btn
                icon
                @click="editDomain(item)">
                <font-awesome-icon icon="pencil-alt" />
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { fetchDomains } from "@/api/domain.js";
import { i18n } from "@/i18n";
import ContentLoader from "@/mixins/ContentLoader.js";

export default {
  name: "DomainIndex",
  mixins: [ContentLoader],
  data() {
    return {
      domains: [],
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          value: "id",
          text: i18n.t("attributes.domains.id"),
          align: "center",
        },
        {
          value: "url",
          text: i18n.t("attributes.domains.url"),
          align: "center",
        },
        {
          value: "action",
          text: i18n.t("actions.text"),
          sortable: false,
          align: "center",
        },
      ];
    },
  },
  created() {
    this.$startLoading();
    fetchDomains().
      then(({ data }) => {
        this.domains = data.domains;
      }).
      finally(() => {
        this.$finishedLoading();
      });
  },
  methods: {
    editDomain(domain) {
      this.$router.push({
        name: this.$routes.domain.editRoute.name,
        params: { id: domain.id },
      });
    },
  },
};
</script>

<style scoped>

</style>
